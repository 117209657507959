import { ReactNode } from 'react';
import { Box, Text, VStack } from 'ui';
type Props = {
  imgEl: ReactNode;
  title: string;
  description: string;
  onClick(): void;
};
const ProductBox = ({
  imgEl,
  title,
  description,
  onClick
}: Props) => {
  return <Box as="button" className="product-box" bg={'cloud_night'} p="5" alignItems={'flexStart'} bRadius={'2'} boxShadowed css={{
    maxWidth: '400rem',
    color: '$white',
    transition: '.3s',
    '&:hover': {
      background: '$sky'
    }
  }} onClick={onClick} data-sentry-element="Box" data-sentry-component="ProductBox" data-sentry-source-file="ProductBox.tsx">
            <VStack alignItems={'flexStart'} data-sentry-element="VStack" data-sentry-source-file="ProductBox.tsx">
                {imgEl}

                <Text size={'4'} textTransform={'capitalize'} data-sentry-element="Text" data-sentry-source-file="ProductBox.tsx">
                    {title}
                </Text>
                <Text as="p" textAlign={'left'} data-sentry-element="Text" data-sentry-source-file="ProductBox.tsx">
                    {description}
                </Text>
            </VStack>
        </Box>;
};
export default ProductBox;