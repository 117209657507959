import ProductBoxList from '@components/UtilComponents/ProductBoxList';
import MuxPlayer from '@mux/mux-player-react';
import '@mux/mux-player/themes/microvideo';
import { useRouter } from 'next/router';
import LandingStatemintSvg1 from 'share-svgs/svgs/landing-svg-1.svg';
import LandingStatemintSvg2 from 'share-svgs/svgs/landing-svg-2.svg';
import { Box, Button, Container, Flex, MuxVideoPlayerWrapper, Text, VStack } from 'ui';
import { styled } from 'ui/theme/stitches.config';
const LandingPage = () => {
  const router = useRouter();
  const handleRedirectToGetStarted = () => {
    router.push('/get-started');
  };
  return <Flex direction={'col'} data-sentry-element="Flex" data-sentry-component="LandingPage" data-sentry-source-file="LandingPage.tsx">
            <HeaderSection data-sentry-element="HeaderSection" data-sentry-source-file="LandingPage.tsx">
                <Container size="xl" css={{
        height: '100%',
        padding: '$4',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        position: 'relative',
        '@md': {
          padding: '0 $4',
          justifyContent: 'center',
          alignItems: 'center'
        }
      }} data-sentry-element="Container" data-sentry-source-file="LandingPage.tsx">
                    <Flex direction={'col'} alignItems={'flexStart'} css={{
          maxWidth: '450rem',
          '@md': {
            position: 'relative',
            right: '100rem'
          },
          '@lg': {
            right: '250rem'
          }
        }} data-sentry-element="Flex" data-sentry-source-file="LandingPage.tsx">
                        <Text as="h1" size={'5'} color={'dark_lighter'} weight={'light'} css={{
            mb: '$3',
            display: 'flex',
            alignItems: 'center',
            '@lg': {
              fontSize: '40rem'
            }
          }} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                            Make a&nbsp;
                            <Text weight={'bold'} size={'5'} css={{
              background: 'var(--gradient-1, linear-gradient(180deg, #63B9D7 0%, #4698B4 100%))',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              fontFeatureSettings: "'clig' off, 'liga' off",
              lineHeight: 'normal',
              '@lg': {
                fontSize: '40rem'
              }
            }} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                Statemint
                            </Text>
                        </Text>

                        <VStack alignItems={'flexStart'} data-sentry-element="VStack" data-sentry-source-file="LandingPage.tsx">
                            <Text color={'dark_lighter'} css={{
              width: '300rem',
              '@lg': {
                width: 'auto',
                fontSize: '$3'
              }
            }} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                Discover, highlight & curate the insights most
                                valuable to you, sourced from incredible
                                conversations and events.
                            </Text>
                            <Button onClick={handleRedirectToGetStarted} data-sentry-element="Button" data-sentry-source-file="LandingPage.tsx">
                                let&apos;s connect
                            </Button>
                        </VStack>
                    </Flex>

                    <LandingImg src="/landing/header-section.webp" alt="landing image" loading="eager" data-sentry-element="LandingImg" data-sentry-source-file="LandingPage.tsx" />
                </Container>
            </HeaderSection>

            <StatementSection data-sentry-element="StatementSection" data-sentry-source-file="LandingPage.tsx">
                <Flex direction={'col'} alignItems={'flexStart'} css={{
        width: '50%',
        p: '0 $4',
        '& .text': {
          textAlign: 'center',
          fontSize: '22rem',
          lineHeight: 1.5
        },
        '& .text:first-of-type': {
          mb: '$3'
        },
        '@md': {
          width: 'auto',
          '& .text': {
            textAlign: 'left',
            fontSize: '40rem',
            lineHeight: 'normal'
          },
          '& .text:first-of-type': {
            mb: 0
          }
        },
        '@lg': {
          '& .text': {
            textAlign: 'left',
            fontSize: '60rem',
            lineHeight: 'normal'
          }
        }
      }} data-sentry-element="Flex" data-sentry-source-file="LandingPage.tsx">
                    <Text className="text" as="p" size={'4'} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                        A{' '}
                        <Text className="text" weight={'bold'} size={'4'} inline data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                            Statemint
                        </Text>{' '}
                        is a supercut of insights
                    </Text>
                    <Text className="text" as="p" color={'orange'} size={'4'} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                        you curate from conversations
                    </Text>
                </Flex>

                <PositionedLandingStatemintSvg1 data-sentry-element="PositionedLandingStatemintSvg1" data-sentry-source-file="LandingPage.tsx" />
                <PositionedLandingStatemintSvg2 data-sentry-element="PositionedLandingStatemintSvg2" data-sentry-source-file="LandingPage.tsx" />
            </StatementSection>

            <VideoSection data-sentry-element="VideoSection" data-sentry-source-file="LandingPage.tsx">
                <Container size="lg" css={{
        height: '100%',
        padding: '$4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        '@md': {
          padding: '50rem $4',
          justifyContent: 'center'
        },
        '@lg': {
          padding: '80rem 0'
        }
      }} data-sentry-element="Container" data-sentry-source-file="LandingPage.tsx">
                    <Text as="h2" size={'5'} color={'dark'} weight={'bold'} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                        How it Works
                    </Text>
                    <MuxVideoPlayerWrapper css={{
          mt: '$4'
        }} data-sentry-element="MuxVideoPlayerWrapper" data-sentry-source-file="LandingPage.tsx">
                        <MuxPlayer theme="microvideo" thumbnailTime={23} playbackId={'B1pouiZNjmylFKVogDLGunOHzkV8XiUW91pu6VC3iFQ'} metadata={{
            video_id: 'Statemints',
            video_title: 'Statemints introduction'
          }} defaultHiddenCaptions={true} data-sentry-element="MuxPlayer" data-sentry-source-file="LandingPage.tsx" />
                    </MuxVideoPlayerWrapper>
                </Container>
            </VideoSection>

            <ProductSection data-sentry-element="ProductSection" data-sentry-source-file="LandingPage.tsx">
                <Container size="lg" css={{
        height: '100%',
        padding: '$4',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        '@md': {
          padding: '50rem $4',
          justifyContent: 'center'
        },
        '@lg': {
          padding: '80rem 0'
        }
      }} data-sentry-element="Container" data-sentry-source-file="LandingPage.tsx">
                    <ProductBoxList data-sentry-element="ProductBoxList" data-sentry-source-file="LandingPage.tsx" />

                    <Box bg={'light_gray'} p={'5'} bRadius={'2'} css={{
          border: '1rem solid $gray',
          color: '$dark_lighter',
          flexDirection: 'column',
          alignItems: 'center',
          '@md': {
            flexDirection: 'row',
            alignItems: 'flex-start'
          }
        }} data-sentry-element="Box" data-sentry-source-file="LandingPage.tsx">
                        <StyledWGAImg src="/landing/wga.webp" alt="#WGAStrong image" loading="lazy" data-sentry-element="StyledWGAImg" data-sentry-source-file="LandingPage.tsx" />

                        <VStack alignItems={'flexStart'} data-sentry-element="VStack" data-sentry-source-file="LandingPage.tsx">
                            <Text as="p" weight={'bold'} data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                Statemints is devoted to supporting the artists,
                                their craft and a sustainable future.
                            </Text>
                            <Text as="p" data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                Jogg, the parent company of Statemints, is
                                dedicated to supporting the WGA and all
                                screenwriters and creative talent in the
                                entertainment industry.
                            </Text>
                            <Text as="p" data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                We refrain from allowing the video, audio,
                                transcriptions or any data accessible from our
                                platform to be used for machine learning or
                                generative AI purposes.
                            </Text>
                            <Text as="p" data-sentry-element="Text" data-sentry-source-file="LandingPage.tsx">
                                We believe platforms that do allow such data to
                                be used for generative AI are ultimately
                                extinguishing the ability for artists&apos; hard
                                work and unique insights to be passed onto
                                future generations in a safe and sustainable
                                fashion.
                            </Text>
                        </VStack>
                    </Box>
                </Container>
            </ProductSection>
        </Flex>;
};
const HeaderSection = styled('section', {
  width: '100%',
  height: '280rem',
  background: '$white',
  '@md': {
    height: '350rem'
  },
  '@lg': {
    height: '490rem'
  }
});
const LandingImg = styled('img', {
  width: '230rem',
  position: 'absolute',
  right: 0,
  bottom: 0,
  '@md': {
    width: '400rem',
    margin: 'auto 0',
    right: 0,
    top: 0,
    bottom: 0
  },
  '@lg': {
    width: '750rem'
  },
  '@hq': {
    width: 'auto'
  }
});
const StatementSection = styled('section', {
  width: '100%',
  height: '250rem',
  background: '$cloudy_night',
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  overflow: 'hidden',
  '@md': {
    height: '530rem'
  }
});
const PositionedLandingStatemintSvg1 = styled(LandingStatemintSvg1, {
  width: '100rem',
  position: 'absolute',
  left: 0,
  bottom: '-50rem',
  zIndex: '-1',
  '@md': {
    width: '250rem'
  },
  '@lg': {
    width: '500rem',
    bottom: '-330rem'
  }
});
const PositionedLandingStatemintSvg2 = styled(LandingStatemintSvg2, {
  width: '100rem',
  position: 'absolute',
  right: 0,
  top: 0,
  zIndex: '-1',
  '@md': {
    width: '400rem',
    top: '-160rem'
  },
  '@lg': {
    width: '550rem',
    top: '-200rem'
  }
});
const ProductSection = styled('section', {
  width: '100%',
  background: '$white'
});
const VideoSection = styled('section', {
  width: '100%',
  background: '$white_smoke'
});
const StyledWGAImg = styled('img', {
  width: '100%',
  maxWidth: '135rem',
  mb: '$4',
  '@md': {
    mb: 0,
    mr: '$5'
  }
});
export default LandingPage;