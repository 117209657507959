import { useRouter } from 'next/router';
import { ReactNode, useState } from 'react';
import NotepadWhite from 'share-svgs/svgs/notepad-white.svg';
import StatemintsWhiteLogo from 'share-svgs/svgs/statemints-white-logo.svg';
import { Box, Flex } from 'ui';
import { styled } from 'ui/theme/stitches.config';
import ProductBox from './ProductBox';
interface ProductListItem {
  route: string;
  imgEl: ReactNode;
  title: string;
  description: string;
}
const ProductBoxList = () => {
  const router = useRouter();
  const [productList] = useState<ProductListItem[]>([{
    route: 'aff',
    imgEl: <Box css={{
      width: '60rem',
      height: '60rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '$round',
      background: '$black'
    }}>
                    <StyledAFFImg src="/landing/aff.webp" alt="austin film festival logo" />
                </Box>,
    title: 'austin film festival',
    description: 'Furthers the art and craft of storytelling by inspiring and championing the work of writers, filmmakers, and all artists.'
  }, {
    route: 'product',
    imgEl: <StatemintsWhiteLogo className="statemints-white-svg" />,
    title: 'our product',
    description: 'Learn more about how Statemints enables brand fans and supporters an opportunity to further engage.'
  }, {
    route: 'faq',
    imgEl: <NotepadWhite className="notepad-white-svg" />,
    title: 'FAQs',
    description: 'Want to know more about how our platform works? Read our frequently asked questions, or ask your own.'
  }]);
  const handleRedirectionRoute = (route: string) => {
    router.push(`/${route}`);
  };
  return <Flex css={{
    mb: '$8',
    flexDirection: 'column',
    alignItems: 'center',
    '& .statemints-white-svg': {
      height: '50rem'
    },
    '& .notepad-white-svg': {
      height: '40rem',
      position: 'relative',
      left: '-6rem'
    },
    '& .product-box': {
      mb: '$3'
    },
    '@lg': {
      flexDirection: 'row',
      alignItems: 'stretch',
      '& .product-box': {
        mb: '0',
        mr: '$4'
      },
      '& .product-box:last-of-type': {
        mr: '0'
      }
    }
  }} data-sentry-element="Flex" data-sentry-component="ProductBoxList" data-sentry-source-file="ProductBoxList.tsx">
            {productList.map((item: ProductListItem) => <ProductBox key={item.title} imgEl={item.imgEl} title={item.title} description={item.description} onClick={() => handleRedirectionRoute(item.route)} />)}
        </Flex>;
};
const StyledAFFImg = styled('img', {
  width: '40rem',
  borderRadius: '$round'
});
export default ProductBoxList;